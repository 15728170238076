import { AgendamentoPedidosRepository } from "../../../repositories/defaultUserRepositories/AgendamentoPedidosRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import store from "../../store";

export default {
  async findAgendamentoPedidos({ commit, state, dispatch }) {
    commit("setIsLoading", true);
    commit("setListingAgePedidos", []);
    try {
      state.urlParams.unidadeId = store.getters["login/getFilial"].id;
      const response =
        await AgendamentoPedidosRepository.requestAgendamentoPedidos({
          ...state.urlParams,
          numeroPedidoWinthor: state.urlParams.numeroPedidoWinthor
            ? [state.urlParams.numeroPedidoWinthor]
            : "",
        });
      if (response.data.statusCode >= 200 && response.data.statusCode < 300) {
        commit("setListingAgePedidos", response.data.data.list);
        commit("setAgePedidosPagination", response.data.data.pagination);
        commit("setIsLoading", false);
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async agendarEntrega({ commit, dispatch }, payload) {
    try {
      const response = await AgendamentoPedidosRepository.requestAgendarEntrega(
        payload
      );
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return true;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async generateOrdersReport({ commit }, { body, fileName, fileFormat }) {
    try {
      const response = await AgendamentoPedidosRepository.generateOrdersReport(
        body,
        fileName,
        fileFormat
      );
      if (response?.status === 200) {
        commit(
          "notifications/setSuccessNotification",
          {
            successStatus: true,
            successMessage: "Relatório gerado com sucesso",
          },
          { root: true }
        );
        return;
      }
      commit(
        "notifications/setErrorNotification",
        { errorStatus: true, errorMessage: response?.message },
        { root: true }
      );
    } catch (error) {
      console.log(error);
    }
  },

  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  haveFilters({ commit }, value) {
    commit("setHaveFilters", value);
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },

  setPedidosReenvio({ commit }, value) {
    commit("setPedidosReenvio", value);
  },

  setReenvioDialog({ commit }, value) {
    commit("setReenvioDialog", value);
  },
};
