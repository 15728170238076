import { ReceptionRepository } from "../../../repositories/defaultUserRepositories/ReceptionRepository";
import store from "../../../store/store";

export default {
  async setDefaultState({ commit }) {
    commit("setIsLoading", false);
    commit("setDataReception", []);
  },

  async reception({ commit }, payload) {
    commit("setIsLoading", true);
    commit("setDataReception", []);
    const response = await ReceptionRepository.requestReception(payload);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Protocolo encontrado com sucesso.",
      };

      const dataMapped = response.data.data.map((protocolo) => ({
        protocoloId: protocolo.protocoloId,
        protocolo: protocolo.protocolo,
        statusProtocolo: protocolo.statusProtocolo,
        dataEnvioProtocolo: protocolo.dataEnvioProtocolo,
        coletaId: protocolo.coletaId,
        dataInicioEmbarque: protocolo.dataInicioEmbarque,
        dataFimEmbarque: protocolo.dataFimEmbarque,
        statusColeta: protocolo.statusColeta,
        transportadoraId: protocolo.transportadoraId,
        razaoSocial: protocolo.razaoSocial,
        nomeFantasia: protocolo.nomeFantasia,
        docaId: protocolo.docaId,
        docaCor: protocolo.docaCor,
        docaCodigo: protocolo.docaCodigo,
        placaVeiculo: protocolo.placaVeiculo,
        tipoVeiculo: protocolo.tipoVeiculo,
        nomeMotorista: protocolo.nomeMotorista,
        cpfMotorista: protocolo.cpfMotorista,
        habilitaConfirmacaoMotorista:
          protocolo.statusColeta === "AGUARDANDO_TRANSPORTADORA",
      }));
      commit("setDataReception", dataMapped);
      commit("setIsLoading", false);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    commit("setIsLoading", false);
    commit(
      "notifications/setErrorNotification",
      {
        errorStatus: true,
        errorMessage: response.data.message,
      },
      { root: true }
    );
  },

  async receptionDetails({ commit }, coletaId) {
    const response = await ReceptionRepository.requestReceptionDetails(
      coletaId
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Detalhes do protocolo encontrados com sucesso.",
      };
      commit("setReceptionDetails", response.data.data[0]);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return;
    }
    commit(
      "notifications/setErrorNotification",
      {
        errorStatus: true,
        errorMessage: response.data.message,
      },
      { root: true }
    );
  },

  async confirmDriver({ commit }, coletaId) {
    const response = await ReceptionRepository.confirmDriver(coletaId);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: "Motorista confirmado com sucesso.",
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      commit("setDataReception", "");
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },

  async receptionList({ commit }, urlParams) {
    console.log(urlParams);
    let url = `/veiculo/listar/agendados?page=${urlParams.page}&perPage=${10}`;
    url = url.concat(`&unidadeId=${store.getters["login/getFilial"].id}`);
    if (urlParams.transportadora) {
      url = url.concat(`&transportadoraNome=${urlParams.transportadora}`);
    }
    if (urlParams.placa) {
      url = url.concat(`&placa=${urlParams.placa}`);
    }
    if (urlParams.coleta) {
      url = url.concat(`&numeroColeta=${urlParams.coleta}`);
    }
    commit("setReceptionList", []);
    const response = await ReceptionRepository.receptionList(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setReceptionList", response.data.data.list);
      commit("setReceptionPagination", response.data.data.pagination);
      return;
    }
    let errorObj = {
      errorStatus: true,
      errorMessage: "Data não encontrada",
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
  },
  async sendMotivoAtraso({ commit }, body) {
    const response = await ReceptionRepository.sendMotivoAtraso(body);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return true;
    }
    commit("setLoadingReception", false);
    let errorObj = {
      errorStatus: true,
      errorMessage: response.data.message,
    };
    commit("notifications/setErrorNotification", errorObj, { root: true });
    return false;
  },

  setLoadingReception({ commit }, value) {
    commit("setLoadingReception", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },
  setDataInicial({ commit }, value) {
    commit("setDataInicial", value);
  },
};
