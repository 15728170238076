import OrderBlockingRepository from "../../../repositories/defaultUserRepositories/OrderBlockingRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import store from "../../store";

import state from "./state";

export default {
  async orders({ state, commit, dispatch }) {
    commit("setIsLoadingOrders", true);
    commit("setOrders", []);
    try {
      state.urlParams.unidadeId = store.getters["login/getFilial"].id;
      const response = await OrderBlockingRepository.getOrders(state.urlParams);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        const orders = response.data.data.list;
        const pagination = response.data.data.pagination;
        commit("setOrders", orders);
        commit("setPagination", pagination);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    } finally {
      commit("setIsLoadingOrders", false);
    }
  },

  async blockingReasons({ commit, dispatch }) {
    try {
      const response = await OrderBlockingRepository.getBlockingReasons();
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        const blockingReasons = response.data.data;
        commit("setBlockingReasons", blockingReasons);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async postUnblockingOrder({ commit, dispatch }, payload) {
    try {
      const response = await OrderBlockingRepository.postUnblockingOrder(
        payload
      );
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: "Desbloqueio feito com sucesso!",
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async postBlockingOrder({ commit, dispatch }, payload) {
    try {
      const response = await OrderBlockingRepository.postBlockingOrder(payload);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: "Bloqueio feito com sucesso!",
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
      return error;
    }
  },

  async getDetails({ commit, dispatch }, body) {
    commit("setIsLoadingDetails", true);
    commit("setBlockingDetails", {});
    try {
      const response = await OrderBlockingRepository.getBlockingDetails(body);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        const blockingDetails = response.data.data;
        commit("setBlockingDetails", blockingDetails);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    } finally {
      commit("setIsLoadingDetails", false);
    }
  },

  setUrlParams({ commit }, payload) {
    commit("setUrlParams", payload);
  },

  setCurrentPageToFirstPage({ commit, state }) {
    commit("setUrlParams", { ...state.urlParams, page: 1 });
  },

  resetUrlParams({ commit }) {
    const originalUrlParams = state.urlParams;
    commit("setUrlParams", originalUrlParams);
  },

  setIsBlockingModalOpen({ commit }, payload) {
    commit("setIsBlockingModalOpen", payload);
  },

  setIsBlockingDetailsModalOpen({ commit }, payload) {
    commit("setIsBlockingDetailsModalOpen", payload);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
};
