<template>
  <v-card class="overflow-hidden">
    <v-card-title class="justify-center">
      <span class="text-h6 font-weight-bold navy--text">
        {{ isPausedCollection ? "Continuar Embarque" : "Iniciar Embarque" }}
      </span>
    </v-card-title>
    <div v-if="!isLoadingModal">
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col>
                <v-row class="mb-1">
                  <span class="font-weight-bold navy--text"
                    >Nº de Protocolo</span
                  >
                </v-row>
                <v-row class="mb-1">
                  <v-text-field
                    label="Nº de Protocolo"
                    placeholder="Nº de Protocolo"
                    v-model="protocolModel"
                    :rules="protocolRules"
                    @keydown.enter.prevent="validateProtocol"
                    outlined
                    dense
                    solo
                    flat
                  >
                  </v-text-field>
                </v-row>
              </v-col>
            </v-row>
            <span v-if="validBoarding">
              <v-row justify="space-between">
                <v-col class="mr-4">
                  <v-row class="mb-1">
                    <span class="font-weight-bold navy--text"
                      >Conferir Motorista</span
                    >
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="selectDrivers"
                      label="Nome não encontrado"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="mb-1">
                    <span class="font-weight-bold navy--text"
                      >Conferir Baia</span
                    >
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="selectDocks"
                      label="Doca não encontrada"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row justify="space-between">
                <v-col class="mr-4">
                  <v-row class="mb-1">
                    <span class="font-weight-bold navy--text"
                      >Conferir Tipo de Veículo</span
                    >
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="selectVehicle"
                      label="Tipo de veículo não encontrado"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="mb-1">
                    <span class="font-weight-bold navy--text"
                      >Conferir Placa Veículo</span
                    >
                  </v-row>
                  <v-row>
                    <v-text-field
                      v-model="selectPlaca"
                      label="Placa não encontrada"
                      outlined
                      readonly
                      dense
                      solo
                      flat
                    >
                    </v-text-field>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-row class="mb-1">
                    <span class="font-weight-bold navy--text"
                      >Escolha um empilhador para cuidar desse pedido
                    </span>
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      v-model="selectForklifts"
                      :items="getForklifts.list"
                      :rules="[(v) => !!v || 'Empilhador é Obrigatório']"
                      item-text="userName"
                      item-value="userId"
                      label="Selecionar o Empilhador"
                      placeholder="Selecionar o Empilhador"
                      outlined
                      dense
                      solo
                      flat
                    >
                    </v-autocomplete>
                  </v-row>
                </v-col>
              </v-row>
            </span>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row justify="center">
          <v-col cols="4" v-if="!validBoarding">
            <v-hover v-slot="{ hover }">
              <v-btn
                x-large
                block
                rounded
                color="primary"
                class="text-truncate"
                style="text-transform: none !important; font-weight: bolder"
                :class="hover ? 'base--text' : 'navy--text'"
                :loading="isLoadingBtn"
                :disabled="!valid"
                @click="validateProtocol"
                >Conferir Protocolo</v-btn
              >
            </v-hover>
          </v-col>
          <v-col cols="4" v-else>
            <v-hover v-slot="{ hover }">
              <v-btn
                x-large
                block
                rounded
                color="primary"
                class="text-truncate"
                style="text-transform: none !important; font-weight: bolder"
                :class="hover ? 'base--text' : 'navy--text'"
                :loading="isLoadingBtn"
                :disabled="!valid"
                @click="validateStartBoarding"
              >
                {{
                  isPausedCollection ? "Continuar Embarque" : "Iniciar Embarque"
                }}
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
    <v-col v-else>
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-col>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    validBoarding: false,
    isLoadingModal: false,
    isLoadingBtn: false,
    protocolModel: "",
    selectDrivers: "",
    selectDocks: 0,
    selectVehicle: "",
    selectPlaca: "",
    selectForklifts: null,
    protocolRules: [(v) => !!v || "Protocolo é Obrigatório"],
  }),
  props: {
    coletaId: { type: Number, required: true },
    coletaStatus: { type: String, required: true },
  },
  mounted() {
    this.startLists();
  },
  computed: {
    ...mapGetters("expedition", ["getForklifts", "getProtocolDetails"]),
    ...mapActions("expedition", ["listForklifts"]),
    ...mapGetters("login", ["getFilial"]),

    isPausedCollection() {
      return this.coletaStatus === "COLETA_PAUSADA";
    },
  },
  methods: {
    ...mapActions("expedition", [
      "sendBoarding",
      "continueBoarding",
      "protocolDetails",
    ]),

    // async onStartPausedBoarding() {},

    async onStartBoarding() {
      this.isLoadingBtn = true;
      const modelSendBoarding = {
        empilhador_pcempr_id: ~~this.selectForklifts,
        protocolo: this.protocolModel,
        coleta_id: ~~this.coletaId,
        doca_id: ~~this.getProtocolDetails.docaId,
        motorista_id: ~~this.getProtocolDetails.motoristaId,
      };
      const success = await this.sendBoarding(modelSendBoarding);
      this.isLoadingBtn = false;
      if (success) {
        if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_PADRAO" ||
          this.getProtocolDetails.tipoColeta == "EMBARQUE_ENCAMINHAMENTO"
        ) {
          await this.$router.push({
            name:
              this.getFilial.id === 1
                ? "Embarque de Coleta (Matriz)"
                : "Embarque de Coleta (Regional Norte)",
            params: { coletaId: this.coletaId },
          });
        } else if (this.getProtocolDetails.tipoColeta == "EMBARQUE_DINAMICO") {
          await this.$router.push({
            name: "Embarque de Cooktop",
            params: { coletaId: this.coletaId },
          });
        } else if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_REDESPACHO"
        ) {
          await this.$router.push({
            name: "Embarque de Coleta (Filial Bahia)",
            params: { coletaId: this.coletaId },
          });
        }
      }
      // this.$emit("listExpedition");
      // this.$emit("closeStartBoardingDialog");
    },

    async onContinueBoarding() {
      this.isLoadingBtn = true;
      const modelContinueBoarding = {
        protocolo: this.protocolModel,
        coletaId: ~~this.coletaId,
        empilhador: ~~this.selectForklifts,
      };
      const success = await this.continueBoarding(modelContinueBoarding);
      this.isLoadingBtn = false;
      if (success) {
        if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_PADRAO" ||
          this.getProtocolDetails.tipoColeta == "EMBARQUE_ENCAMINHAMENTO"
        ) {
          await this.$router.push({
            name:
              this.getFilial.id === 1
                ? "Embarque de Coleta (Matriz)"
                : "Embarque de Coleta (Regional Norte)",
            params: { coletaId: this.coletaId },
          });
        } else if (this.getProtocolDetails.tipoColeta == "EMBARQUE_DINAMICO") {
          await this.$router.push({
            name: "Embarque de Cooktop",
            params: { coletaId: this.coletaId },
          });
        } else if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_REDESPACHO"
        ) {
          await this.$router.push({
            name: "Embarque de Coleta (Filial Bahia)",
            params: { coletaId: this.coletaId },
          });
        }
      }
    },

    async onGetProtocolDetails() {
      this.isLoadingBtn = true;
      await this.protocolDetails(this.protocolModel);
      if (~~this.getProtocolDetails.coletaId !== this.coletaId) {
        this.protocolModel = "";
        this.isLoadingBtn = false;
        return;
      }
      this.selectDrivers = this.getProtocolDetails.nomeMotorista;
      this.selectDocks = this.getProtocolDetails.docaCodigo;
      this.selectVehicle = this.getProtocolDetails.tipoVeiculo;
      this.selectPlaca = this.getProtocolDetails.placaVeiculo;
      this.validBoarding = true;
      this.isLoadingBtn = false;
      if (
        this.getProtocolDetails.irParaTelaEmbarque &&
        this.getProtocolDetails.statusColeta != "COLETA_PAUSADA"
      ) {
        // Essa condição irá ser satisfeita quando o embarque da coleta tiver sido iniciado
        // por esse mesmo usuário e o status da coleta for diferente de COLETA_PAUSADA.
        if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_PADRAO" ||
          this.getProtocolDetails.tipoColeta == "EMBARQUE_ENCAMINHAMENTO"
        ) {
          await this.$router.push({
            name:
              this.getFilial.id === 1
                ? "Embarque de Coleta (Matriz)"
                : "Embarque de Coleta (Regional Norte)",
            params: { coletaId: this.coletaId },
          });
        } else if (this.getProtocolDetails.tipoColeta == "EMBARQUE_DINAMICO") {
          await this.$router.push({
            name: "Embarque de Cooktop",
            params: { coletaId: this.coletaId },
          });
        } else if (
          this.getProtocolDetails.tipoColeta == "EMBARQUE_REDESPACHO"
        ) {
          await this.$router.push({
            name: "Embarque de Coleta (Filial Bahia)",
            params: { coletaId: this.coletaId },
          });
        }
      }
    },

    async startLists() {
      this.isLoadingModal = true;
      await this.listForklifts;
      this.isLoadingModal = false;
    },

    async validateProtocol() {
      this.protocolModel !== ""
        ? await this.onGetProtocolDetails()
        : this.$refs.form.validate();
    },

    validateStartBoarding() {
      this.protocolModel !== "" && this.selectForklifts !== null
        ? this.isPausedCollection
          ? this.onContinueBoarding()
          : this.onStartBoarding()
        : this.$refs.form.validate();
    },
  },
};
</script>
<style scoped></style>
