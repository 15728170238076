import { HttpRestService } from "@/services/http/HttpRestService";
import store from "../../store/store";
export class TransportadoraRepository {
  static requestTransportadora() {
    let url = "/transportadora/pedidos";
    url = url.concat(`?unidadeId=${store.getters["login/getFilial"].id}`);
    return HttpRestService.get(url);
  }

  static updateTransportadora(modelUpdateTransportadora) {
    return HttpRestService.post(
      `/transportadora/email/criar`,
      modelUpdateTransportadora
    );
  }

  static listEmailTransportadora(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/email/listar/${transportadoraId}`
    );
  }

  static resendUp(modelResend) {
    return HttpRestService.post(`/transportadora/enviar/email`, modelResend);
  }
}
