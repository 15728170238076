import { HttpRestService } from "@/services/http/HttpRestService";
import store from "../../store/store";

export class RemessasRepository {
  static requestRemessas(url) {
    return HttpRestService.get(url);
  }

  static requestColetaByRemessa(url) {
    return HttpRestService.get(url);
  }

  static requestCadastrarRemessa(payload) {
    this.unidadeId = store.getters["login/getFilial"].id;
    payload.unidadeId = this.unidadeId;
    return HttpRestService.post("/remessa", payload);
  }

  static requestCadastrarRedespacho(payload) {
    return HttpRestService.post("/remessa-encaminhamento", payload);
  }

  static requestConfirmDivergence(payload) {
    return HttpRestService.post("/remessa/resolver/divergencia", payload);
  }

  static requestConfirmDivergenceHorario(payload) {
    return HttpRestService.post(
      "/remessa/resolver/divergencia/horario-data ",
      payload
    );
  }

  static requestDataParaCadastroRemessa(transportadoraId) {
    this.unidadeId = store.getters["login/getFilial"].id;
    return HttpRestService.get(
      `/transportadora/${transportadoraId}/remessa/cadastro?unidadeId=${this.unidadeId}`
    );
  }
  //Divergência de veículos
  static getDetalhesDaDivergencia(remessaId) {
    return HttpRestService.get(`/remessa/detalhes/divergencia/${remessaId}`);
  }
  //Divergencia de horário
  static getDetalhesDaDivergenciaHorario(remessaId) {
    return HttpRestService.get(`/remessa/listar-hora/divergencia/${remessaId}`);
  }
  static requestTransportadorasFilhas(transportadoraId) {
    return HttpRestService.get(
      `/redespacho/transportadora?transportadoraInicialId=${transportadoraId}`
    );
  }

  static pedidosTransportadoraFilha(transportadoraId) {
    return HttpRestService.get(
      `/transportadora/${transportadoraId}/remessa-encaminhamento/cadastro`
    );
  }
}
