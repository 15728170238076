import router from "@/router";
import { AuthRepository } from "../../../repositories/defaultUserRepositories/AuthRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";

export default {
  async login({ commit, dispatch }, payload) {
    commit("setIsLoading", true);
    commit("setTransportadoraData", []);
    const successObj = {
      successStatus: true,
      successMessage: "Usuário logado com sucesso",
    };
    try {
      const response = await AuthRepository.login(payload);
      console.log({ response });
      if (response === undefined) {
        commit("setIsLoading", false);
        throw response;
      }
      if (response.status >= 200 && response.status < 300) {
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        commit("setIsLoading", false);
        commit("setLogin");
        const permissionObj = response.data.data.permissions;
        const permissionMapped = permissionObj.map((a) => a.name);
        commit("setMyPermissions", permissionMapped);
        commit("setUserData", response.data.data);
        commit("setSelectedFilialId", response.data.data.unidadeId);
        localStorage.setItem("token", response.data.data.token);
        if (
          response.data.data.roleSlug === "transportadora" ||
          response.data.data.roleSlug ===
            "transportadora-visualizar-agendamento"
        ) {
          await dispatch("getMe");
          router.push("/remessas");
          return;
        } else {
          router.push("/dashboard");
          return;
        }
      } else {
        commit("setIsLoading", false);
        throw response;
      }
    } catch (error) {
      commit("setIsLoading", false);
      await dispatch("errorMessage", error);
    }
  },

  async logout({ commit, dispatch }) {
    try {
      await dispatch("clearNotifications");
      const response = await AuthRepository.logout();
      if (response.status >= 200 && response.status < 300) {
        dispatch("notifyLogoutSuccess");
        commit("setLogout");
        router.replace("/");
        dispatch("removeLocalStorage");
      } else {
        throw response;
      }
    } catch (error) {
      commit("setLogout");
      router.replace("/");
      dispatch("errorMessage", error);
      dispatch("removeLocalStorage");
    }
  },

  async notifyLogoutSuccess({ commit }) {
    const successObj = {
      successStatus: true,
      successMessage: "Usuário deslogado com sucesso",
    };
    commit("notifications/setSuccessNotification", successObj, { root: true });
  },

  async getMe({ commit }) {
    try {
      const response = await AuthRepository.getMe();
      if (response.status >= 200 && response.status < 300) {
        commit("setTransportadoraData", response.data.data[0]);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async clearNotifications({ rootState }) {
    rootState.notifications.systemNotification.forEach((item) => {
      item.visible = false;
    });
    return;
  },

  async removeLocalStorage() {
    setTimeout(() => {
      Object.keys(JSON.parse(JSON.stringify(localStorage))).forEach((l) =>
        localStorage.removeItem(l)
      );
    }, 500);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  setSelectedFilialId({ commit }, payload) {
    commit("setSelectedFilialId", payload);
  },
};
