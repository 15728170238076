import { HttpRestService } from "@/services/http/HttpRestService";
import store from "../../store/store";

export class DashboardRepository {
  static getDispatchMonitoring() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/coleta/acompanhamento/expedicao?unidadeId=${this.unidadeId}`
    );
  }

  static getDocks() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/coleta/acompanhamento/docas?unidadeId=${this.unidadeId}`
    );
  }

  static getDailyOrders() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/pedido/acompanhamento/sou-out?unidadeId=${this.unidadeId}`
    );
  }

  static requestIndividualPerformance() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/coleta/conferente/acompanhamento?unidadeId=${this.unidadeId}`
    );
  }

  static getDuoPickups() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/coleta/empilhador/acompanhamento?unidadeId=${this.unidadeId}`
    );
  }

  static requestOrdersPerShippingCompany() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/dashboard/pedidos/embarcados/transportadoras?unidadeId=${this.unidadeId}`
    );
  }

  static requestStatusOfTrucksOnArrival() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/dashboard/entrada/status?unidadeId=${this.unidadeId}`
    );
  }

  static getCarrierOrders() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/transportadora/acompanhamento?unidadeId=${this.unidadeId}`
    );
  }

  static shippingByCarrier() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/dashboard/pedidos/despachados/transportadoras?unidadeId=${this.unidadeId}`
    );
  }

  static lateCollections() {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/dashboard/coletas/atrasadas?unidadeId=${this.unidadeId}`
    );
  }
}
