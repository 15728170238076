import { PalletsRepository } from "../../../repositories/defaultUserRepositories/PalletsRepository";
import store from "../../store";

export default {
  async findPallets({ commit, state }) {
    const urlParams = state.urlParams;
    let url = `/pallet/v2/listar?page=${urlParams.page}&perPage=${10}`;
    if (urlParams.status !== undefined && urlParams.status !== null) {
      url = url.concat(`&ativo=${urlParams.status}`);
    }
    if (urlParams.painelId !== null && urlParams.painelId !== undefined) {
      url = url.concat(`&tipoInsumoId=${urlParams.painelId}`);
    }
    url = url.concat(`&unidadeId=${store.getters["login/getFilial"].id}`);
    commit("setIsLoading", true);
    const response = await PalletsRepository.findPellets(url);
    if (response.status >= 200 && response.status < 300) {
      commit("setListingPallets", response.data.data.list);
      commit("setPalletsPagination", response.data.data.pagination);
      commit("setIsLoading", false);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na listagem",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },

  async createPallet({ commit }, payload) {
    const response = await PalletsRepository.createPallet(payload);
    let successObj = {
      successStatus: true,
      successMessage: "Palete criado com sucesso.",
    };
    if (response.status >= 200 && response.status < 300) {
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao criar o palete",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },

  async editPallet({ commit }, payload) {
    const response = await PalletsRepository.editPallet(payload);
    let successObj = {
      successStatus: true,
      successMessage: response.data.message,
    };
    if (response.status >= 200 && response.status < 300) {
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return true;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao editar o palete",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },

  async getEditPalletDetails({ commit }, payload) {
    const response = await PalletsRepository.getEditPalletDetails(payload);
    if (response.status >= 200 && response.status < 300) {
      return response.data.data;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro ao buscar os detalhes do palete",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
    return;
  },

  setUrlParamsPallet({ commit }, value) {
    commit("setUrlParamsPallet", value);
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
