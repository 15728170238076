export default {
  isLogged: !!localStorage.getItem("token"),
  isAdministrator: false,
  token: "",
  userData: [],
  meRole: [],
  myPermissions: [],
  isLoading: false,
  transportadoraData: [],
  selectedFilialId: null,
  filiaisList: [
    { id: 1, label: "Matriz - Ceará" },
    { id: 3, label: "Filial - Pará" },
  ],
};
