import { ColetaRepository } from "../../../repositories/defaultUserRepositories/ColetaRepository";
import store from "../../../store/store";

export default {
  async coleta({ commit }, payload) {
    commit("setIsLoading", true);
    commit("setListaColeta", []);
    let url = `/coleta/listar?page=1&perPage=${10}&remessa=${payload.remessa}`;
    if (store.getters["login/getSlug"] !== "transportadora")
      url = url.concat(`&unidadeId=${store.getters["login/getFilial"].id}`);
    if (payload.search) {
      url = url.concat(`&search=${payload.search}`);
    }
    if (payload.coleta) {
      url = url.concat(`&coleta=${payload.coleta}`);
    }
    const { data, status } = await ColetaRepository.requestColeta(url);
    const { list, pagination } = data.data;
    if (status >= 200 && status < 300) {
      commit("setIsLoading", false);
      commit("setListaColeta", list);
      commit("setColetaPagination", pagination);
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async dataParaColetaCadastro({ commit }, transportadoraId) {
    const response = await ColetaRepository.dataColetaCadastro(
      transportadoraId
    );
    if (response.status >= 200 && response.status < 300) {
      commit("setDataParaColetaCadastro", response.data.data);
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async setDataColetaParaCadastro({ commit }, payload) {
    commit("setDataParaColetaCadastro", payload);
  },

  async cadastrarColeta({ commit, dispatch }, payload) {
    const response = await ColetaRepository.cadastrarColeta(payload);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      var urlParams = {
        page: 1,
        remessa: payload.remessaId,
      };
      await dispatch("coleta", urlParams);
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async editarColeta({ commit, dispatch }, payload) {
    const response = await ColetaRepository.editarColeta(payload);
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
      return response;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async dataParaEditarColeta({ commit }, coletaId) {
    const response = await ColetaRepository.dataParaEditarColeta(coletaId);
    if (response.status >= 200 && response.status < 300) {
      commit("setDataParaEditColeta", response.data.data);
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async listDocks({ commit }) {
    const response = await ColetaRepository.listDocks();
    if (response.status >= 200 && response.status < 300) {
      commit("setDocks", response.data.data);
      return;
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: "Ocorreu um erro na aplicação",
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  async confirmCancellation({ commit }, modelConfirmCancellation) {
    const response = await ColetaRepository.requestConfirmCancellation(
      modelConfirmCancellation
    );
    if (response.status >= 200 && response.status < 300) {
      let successObj = {
        successStatus: true,
        successMessage: response.data.message,
      };
      commit("setIsLoading", false);
      commit("notifications/setSuccessNotification", successObj, {
        root: true,
      });
    } else {
      let errorObj = {
        errorStatus: true,
        errorMessage: response.data.message,
      };
      commit("notifications/setErrorNotification", errorObj, { root: true });
    }
  },

  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
};
