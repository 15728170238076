import { PedidosRepository } from "../../../repositories/defaultUserRepositories/PedidosRepository";
import { ErrorNotifier } from "../../../services/messages/ErrorNotifier";
import store from "../../../store/store";

import env from "/env";
export default {
  async pedidos({ commit, state, dispatch }) {
    const { urlParams } = state;
    commit("setIsLoading", true);
    commit("setListingPedidos", []);
    const params = {
      page: urlParams.page ?? 1,
      perPage: urlParams.perPage ?? 10,
      dataInicio: urlParams.dataInicio ?? "",
      dataFinal: urlParams.dataFinal ?? "",
      coletaId: urlParams.coletaId ?? "",
      numeroPedido: urlParams.numeroPedido ?? "",
      transportadoraNome: urlParams.transportadoraNome ?? "",
      pedidoWeb: urlParams.pedidoWeb ?? "",
      tempoPatio: urlParams.tempoPatio ?? "",
      unidadeId: store.getters["login/getFilial"].id,
    };

    try {
      const response = await PedidosRepository.requestPedidos(params);
      await dispatch("checkToken", response);

      if (response.status >= 200 && response.status < 300) {
        const { data } = response.data;
        commit("setIsLoading", false);
        commit("setListingPedidos", data.list);
        commit("setPedidosPagination", data.pagination);
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async cargaRequests({ commit, dispatch }, numeroPedido) {
    commit("setCargaRequests", []);
    try {
      const response = await PedidosRepository.cargaRequests(numeroPedido);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        commit("setCargaRequests", response.data.data);
        return;
      } else {
        throw response;
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },

  async relatorioCarga({ commit }, pedidoId) {
    window.open(env.baseUrl + `/pedido/relatorio/${pedidoId}`);
  },

  async changeStatusPedido({ commit, dispatch }, data) {
    try {
      const response = await PedidosRepository.changeStatusPedido(data);
      await dispatch("checkToken", response);
      if (response.status >= 200 && response.status < 300) {
        let successObj = {
          successStatus: true,
          successMessage: response.data.message,
        };
        commit("notifications/setSuccessNotification", successObj, {
          root: true,
        });
        return;
      } else {
        let errorObj = {
          errorStatus: true,
          errorMessage: response.data.message,
        };
        commit("notifications/setErrorNotification", errorObj, { root: true });
      }
    } catch (error) {
      await dispatch("errorMessage", error);
    }
  },
  setIsLoading({ commit }, value) {
    commit("setIsLoading", value);
  },
  setDataInicial({ commit }, value) {
    commit("setDataInicial", value);
  },
  setDataFinal({ commit }, value) {
    commit("setDataFinal", value);
  },
  setUrlParams({ commit }, value) {
    commit("setUrlParams", value);
  },

  errorMessage({ commit, dispatch }, value) {
    ErrorNotifier.run(commit, dispatch, value);
    return;
  },

  checkToken({ dispatch }, value) {
    if (value.data.status === "O token é inválido.") {
      value.data.message = "O token é inválido.";
      value.status = "O token é inválido.";
      dispatch("errorMessage", value);
      return;
    }
    return;
  },
  haveFilters({ commit }, value) {
    commit("haveFilters", value);
  },
};
