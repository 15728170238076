<template>
  <v-card flat class="py-1">
    <v-app-bar flat color="base" fixed app class="my-1">
      <v-app-bar-nav-icon
        :title="getDrawer ? 'Fechar Menu' : 'Abrir Menu'"
        @click="toggleSidebar"
      />
      <v-toolbar-title
        class="flex text-start text-h5 text-md-h4 text--text font-weight-bold"
        >{{ name }}</v-toolbar-title
      >
      <v-badge
        class="mr-4"
        v-if="notificationPermission"
        overlap
        left
        :color="getSystemNotification.length > 0 ? 'primary' : 'transparent'"
        :content="getSystemNotification.length"
      >
        <v-icon @click="showListNotification(true)" color="navy">{{
          getSystemNotification.length > 0 ? "mdi-bell-ring" : "mdi-bell"
        }}</v-icon>
      </v-badge>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        left
        transition="slide-x-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            color="transparent"
            class="d-flex flex-row pr-0 pl-1 py-6 px-2"
            v-bind="attrs"
            v-on="on"
            style="text-transform: none"
          >
            <div class="text-end mt-2">
              <span
                :title="getUserName"
                class="d-block text--text text-truncate text-body-1 mb-n3"
              >
                {{ getUserName | formatUsername }}
              </span>
              <span
                style="font-size: 10px !important"
                class="d-block secondary--text text-wrap text-overline mb-n5"
                >{{ getSlug }}
              </span>
              <span
                style="font-size: 10px !important"
                class="text-wrap text-overline font-weight-light"
              >
                {{ getFilial.label }}
              </span>
            </div>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-card max-width="500" min-width="200">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-truncate">{{
                  getUserName
                }}</v-list-item-title>
                <v-list-item-subtitle
                  style="font-size: 10px !important"
                  class="secondary--text text-overline"
                  >{{ getSlug }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="mt-2 mb-2 d-flex justify-center">
              <default-fab
                :isTransportadora="getSlug === 'transportadora'"
                @click="darkMode"
                :tooltip-text="getDarkMode ? 'Modo Claro' : 'Modo Escuro'"
              >
                <v-icon v-if="!getDarkMode">mdi-weather-night</v-icon>
                <v-icon v-else>mdi-white-balance-sunny</v-icon>
              </default-fab>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              class="mt-7 d-flex justify-center"
              v-if="hasPermutaFilial"
            >
              <v-select
                ref="selectFilial"
                v-model="selectedFilial"
                label=""
                outlined
                dense
                :items="filiaisList"
                item-text="label"
                item-value="id"
              ></v-select>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="mt-2 d-flex justify-center">
              <div @click="logout" style="cursor: pointer">
                <v-icon class="mr-1">mdi-logout</v-icon>
                <span>Sair</span>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
    <notification-component />
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import DefaultFab from "../../defaultComponents/DefaultFab.vue";
import NotificationComponent from "./../messages/NotificationComponent.vue";

export default {
  components: {
    DefaultFab,
    NotificationComponent,
  },
  data: () => ({
    menu: false,
    return: {},
    toggle: false,
    iconNotification: "mdi-bell",
    modelIcon: false,
    permissionList: [
      "admin",
      "transportadora",
      "aux-de-logistica",
      "supervisor",
    ],
    notificationPermission: false,
  }),
  props: {
    name: { type: String, required: true },
  },
  mounted() {
    this.darkModeOn();
    this.getNotifications();
  },
  computed: {
    ...mapState("login", ["filiaisList"]),
    ...mapGetters("login", [
      "getUserName",
      "getSlug",
      "getFilial",
      "getMyPermissions",
    ]),
    ...mapGetters("menu", ["getDrawer", "getDarkMode"]),
    ...mapGetters("notifications", ["getSystemNotification"]),
    hasPermutaFilial() {
      return this.getMyPermissions.includes("Permuta Filial");
    },
    selectedFilial: {
      get() {
        return this.getFilial;
      },
      set(selectedFilialId) {
        this.setSelectedFilialId(selectedFilialId);
        window.location.reload();
      },
    },
  },
  methods: {
    ...mapActions("menu", ["setDrawer", "setDarkMode"]),
    ...mapActions("notifications", ["showListNotification"]),
    ...mapActions("login", ["logout", "setSelectedFilialId"]),

    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.setDarkMode(!this.getDarkMode);
      this.toggle = true;
      document.activeElement.blur();
      this.menu = false;
    },

    darkModeOn() {
      this.$vuetify.theme.dark = this.getDarkMode;
      this.toggle = this.getDarkMode;
    },

    primeiroNome(nome) {
      return nome.split(" ")[0];
    },
    toggleSidebar() {
      this.$store.dispatch("menu/setDrawer", !this.getDrawer);
    },

    getNotifications() {
      // if (this.permissionList.includes(this.getSlug)) {
      //   this.notificationPermission = true;
      //   setTimeout(() => {
      //     this.getNotifications();
      //   }, 90000); // 1.5 min
      //   this.$store.dispatch("notifications/getNotificationList");
      // }
    },
  },
  filters: {
    formatUsername(username) {
      if (username.length > 20) {
        return username.substring(0, 20) + "...";
      }
      return username;
    },
  },
};
</script>

<style scoped></style>
