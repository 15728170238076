import Vue from "vue";
import VueRouter from "vue-router";
import { compileToFunctions } from "vue-template-compiler";
import HomeLogin from "../views/defaultUserViews/HomeLogin.vue";
import DetailsReception from "../views/defaultUserViews/DetailsReception";
import VehicleReception from "../views/defaultUserViews/VehicleReception.vue";
import VolumeList from "../components/defaultUserComponents/boarding/VolumeList";
import RemessaCadastro from "../views/defaultUserViews/RemessaCadastro";
import Coleta from "../views/defaultUserViews/Coleta";
import Remessas from "../views/defaultUserViews/RemessasTransportadora.vue";
import EmbarqueColeta from "../views/defaultUserViews/EmbarqueColeta";
import EmbarqueCooktop from "../views/defaultUserViews/EmbarqueCooktop";
import EmbarqueRedespacho from "../views/defaultUserViews/EmbarqueRedespacho";
import RecebimentoColeta from "../views/defaultUserViews/RecebimentoColeta";
import store from "@/store/store";
import NotFoundView from "../views/defaultUserViews/NotFoundView.vue";
import NewUser from "../views/defaultUserViews/NewUser.vue";
import ListColetas from "../views/defaultUserViews/ListagemColetasView.vue";

import ColetaTransportadoraView from "../views/transportadoraUserViews/ColetaTransportadoraView.vue";
import CarrierRegistration from "../views/transportadoraUserViews/CarrierRegistration.vue";
import Transportadoras from "../views/defaultUserViews/Transportadoras.vue";
import Matriz from "../views/defaultUserViews/ExpeditionPlan.vue";
import FilialBahia from "../views/defaultUserViews/BahiaBranch.vue";

// Vue.use(VueRouter);

// const routes = [
//   { name: "Login", path: "/", component: HomeLogin },
//   {
//     name: "Remessas",
//     path: "/transportadoras/:transportadoraId/remessas",
//     component: Remessas,
//   },
//   { name: "Dashboard", path: "/dashboard", component: DashBoard },
//   { name: "Recepção", path: "/detalhes-recepcao", component: DetailsReception },
//   { name: "Manifesto", path: "/manifesto", component: VolumeList },
//   // { name: "Manifesto", path: "/manifesto/:id", component: VolumeList },
//   // { name: "Motorista", path: "/motoristas", component: DriversList },
//   {
//     name: "Transportadoras",
//     path: "/transportadoras",
//     component: Transportadoras,
//   },
//   {
//     name: "Cadastro de remessas",
//     path: "/transportadoras/:transportadoraId/remessas/cadastro",
//     component: RemessaCadastro,
//   },
//   {
//     name: "Coletas Agendadas",
//     path: "/coletas-agendadas",
//     component: ExpeditionPlan,
//   },
//   {
//     name: "Cadastro de Remessas",
//     path: "/transportadoras/:transportadoraId/remessas/cadastro",
//     component: RemessaCadastro,
//   },
//   {
//     name: "Edição de Coletas",
//     path: "/transportadoras/:transportadoraId/remessas/:remessaId/coleta",
//     component: Coleta,
//   },
//   { name: "Detalhes", path: "/detalhes-recepcao", component: DetailsReception },
//   // {
//   //   name: "Gerar Protocolo",
//   //   path: "/gerar-protocolo",
//   //   component: GenerateProtocol,
//   // },
//   // { name: "Manifesto", path: "/manifesto/:id", component: VolumeList },
//   {
//     name: "Embarque de Coleta",
//     path: "/coletas/:coletaId/embarque",
//     component: EmbarqueColeta,
//   },
//   { name: "Motoristas", path: "/motoristas", component: DriversList },
//   { name: "Veículos", path: "/veiculos", component: VehiclesList },
//   { name: "Usuários", path: "/usuarios", component: UsersList },
//   { path: "*", redirect: { name: "Dashboard" } },
// ];

Vue.use(VueRouter);
export const sidebarViews = [
  {
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("../views/defaultUserViews/DashboardNew.vue"),
    meta: {
      needsAuthorization: true,
      needsPermission: false,
      permission: "Ver Pedidos Do Dashboard",
      icon: "mdi-view-dashboard-outline",
    },
  },
  {
    name: "Registros",
    path: "/registros",
    component: () => import("../views/defaultUserViews/PedidosList.vue"),
    meta: {
      needsAuthorization: true,
      needsPermission: true,
      permission: "Ver Registros",
      icon: "mdi-package-variant",
    },
  },
  {
    name: "Recepção",
    path: "/recepcao",
    component: () => import("../views/defaultUserViews/VehicleReception.vue"),
    meta: {
      needsAuthorization: true,
      needsPermission: true,
      permission: "Ver Detalhes De Protocolos",
      icon: "mdi-tag-outline",
    },
  },
  {
    name: "Transportadoras",
    path: "/transportadoras",
    component: Transportadoras,
    // component: () => import("../views/defaultUserViews/Transportadoras.vue"),
    meta: {
      needsAuthorization: true,
      needsPermission: true,
      permission: "Ver Lista De Transportadora",
      icon: "mdi-city",
    },
  },
  // {
  //   name: "Monitoramento de Pedidos",
  //   path: "/monitoramento-de-pedidos",
  //   component: () =>
  //     import("../views/defaultUserViews/MonitoramentoPedidosView.vue"),
  //   meta: {
  //     needsAuthorization: true,
  //     needsPermission: true,
  //     permission: "Ver Pedidos Do Dashboard", //TODO: falta add permissão
  //     icon: "mdi-chart-bar",
  //   },
  // },

  {
    name: "Remessas",
    path: "/remessas",
    component: () =>
      import("../views/transportadoraUserViews/RemessaTransportadoraView.vue"),
    meta: {
      needsAuthorization: true,
      needsPermission: true,
      permission: "Ver Lista de Remessas Transportadora",
      icon: "mdi-rename-box-outline",
    },
  },
  {
    name: "Coletas Agendadas",
    path: "/coletas-agendadas",
    component: Vue.component("ColetasAgendadasSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-file-document-outline",
      isSubmenu: true,
    },
    children: [
      {
        name:
          store.getters["login/getFilial"].id === 1
            ? "Matriz"
            : "Regional Norte",
        path: "matriz",
        component: Matriz,
        // component: () => import("../views/defaultUserViews/ExpeditionPlan.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista De Coleta",
        },
      },
      {
        name: "Filial Bahia",
        path: "filial-bahia",
        component: FilialBahia,
        // component: () => import("../views/defaultUserViews/BahiaBranch.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista Redespacho",
        },
      },
    ],
  },
  {
    name: "Gestão de Pátio",
    path: "/gestao-de-patio",
    component: Vue.component("GestaoDePatioSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-abacus",
      isSubmenu: true,
    },
    children: [
      {
        name: "Previsão de Pátio",
        path: "previsao-de-patio",
        component: () =>
          import("../views/defaultUserViews/PrevisaoPatioView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Previsao de Patio",
        },
      },
      {
        name: "Monitoramento de Pátio",
        path: "monitoramento-de-patio",
        component: () =>
          import("../views/defaultUserViews/MonitoramentoPatioView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Monitoramento Patio",
        },
      },
      // {
      //   name: "Gerenciamento de Coletas",
      //   path: "gerenciamento-de-coletas",
      //   component: ListColetas,
      //   meta: {
      //     needsAuthorization: true,
      //     needsPermission: true,
      //     permission: "Ver Conferencia Coleta",
      //   },
      // },

      {
        name: "Conferência de Baia",
        path: "conferencia-de-baia",
        component: () =>
          import("../views/defaultUserViews/ConferenciaBaiaView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Listagem Conferencia de Baia",
        },
      },
    ],
  },
  {
    name: "Gestão de Bloqueios",
    path: "/gestao-de-bloqueios",
    component: Vue.component("GestaoDeBloqueiosSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-block-helper",
      isSubmenu: true,
    },
    children: [
      {
        name: "Bloqueio de Pedidos",
        path: "bloqueio-de-pedidos",
        component: () => import("../views/defaultUserViews/OrderBlocking.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Tela Bloqueio Pedido",
        },
      },
    ],
  },
  {
    name: "Logística",
    path: "/logistica",
    component: Vue.component("LogisticaSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-truck-delivery",
      isSubmenu: true,
    },
    children: [
      {
        name: "Monitoramento de Pedidos",
        path: "monitoramento-de-pedidos",
        component: () =>
          import("../views/defaultUserViews/MonitoramentoPedidosView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Listagem Monitoramento",
        },
      },
      {
        name: "Agendamento de Pedidos",
        path: "agendamento-de-pedidos",
        component: () =>
          import("../views/defaultUserViews/AgendamentoPedidosView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Listagem Agendamento",
        },
      },
    ],
  },
  {
    name: "Cadastros",
    path: "/cadastros",
    component: Vue.component("CadastrosSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-pencil-plus-outline",
      isSubmenu: true,
    },
    children: [
      {
        name: "Motoristas",
        path: "motoristas",
        component: () => import("../views/defaultUserViews/DriversList.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista Motorista",
        },
      },
      {
        name: "Veículos",
        path: "veiculos",
        component: () => import("../views/defaultUserViews/VehiclesList.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista de Veículos",
        },
      },
      {
        name: "Usuários",
        path: "usuarios",
        component: () => import("../views/defaultUserViews/UsersList.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista de Usuários",
        },
      },
      {
        name: "Perfis",
        path: "perfis",
        component: () => import("../views/defaultUserViews/PerfilUser.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista De Perfil",
        },
      },
    ],
  },
  {
    name: "Configurações",
    path: "/configuracoes",
    component: Vue.component("ConfiguracoesSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-cog-outline",
      isSubmenu: true,
    },
    children: [
      // {
      //   name: "Painéis",
      //   path: "paineis",
      //   component: () => import("../views/defaultUserViews/Paineis.vue"),
      //   meta: {
      //     needsAuthorization: true,
      //     needsPermission: true,
      //     permission: "Ver Paineis",
      //   },
      // },
      {
        name: "Pátio",
        path: "patio",
        component: () => import("../views/defaultUserViews/PatioView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Tela Patio",
        },
      },
      {
        name: "Paletes",
        path: "paletes",
        component: () => import("../views/defaultUserViews/PaleteView.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Pedidos Do Dashboard",
        },
      },
    ],
  },
  {
    name: "Recebimento",
    path: "/recebimento",
    component: Vue.component("RecebimentoSubmenu", {
      ...compileToFunctions("<router-view></router-view>"),
    }),
    meta: {
      icon: "mdi-tray-full",
      isSubmenu: true,
    },
    children: [
      {
        name: "Recebimento",
        path: "recebimento",
        component: () =>
          import("../views/defaultUserViews/PickupReception.vue"),
        meta: {
          needsAuthorization: true,
          needsPermission: true,
          permission: "Ver Lista Recebimento",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes: [
    ...sidebarViews,
    {
      name: "Login",
      path: "/",
      component: HomeLogin,
      beforeEnter: (to, from, next) => {
        const isUserLoggedIn = store.getters["login/getLogin"];
        if (isUserLoggedIn) {
          next({ name: "Dashboard" });
          return;
        }
        next();
        return;
      },
    },
    {
      name: "RegistroTransportadora",
      path: "/cadastro-usuario/:token",
      component: CarrierRegistration,
    },
    {
      name: "CadastroDeUsuario",
      path: "/cadastro-usuario-sou/:token",
      component: NewUser,
    },
    {
      name: "Remessas da Transportadora",
      path: "/transportadoras/:transportadoraId/remessas",
      component: Remessas,
      meta: {
        needsAuthorization: true,
        needsPermission: false,
        permission: "Ver Lista De Remessa",
      },
    },
    {
      name: "Protocolo",
      path: "/reception-protocol",
      component: DetailsReception,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Ver Detalhes De Protocolos",
      },
    },
    {
      name: "Manifesto",
      path: "/manifesto",
      component: VolumeList,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Ver Detalhes De Volume",
      },
    },
    // { name: "Manifesto", path: "/manifesto/:id", component: VolumeList },
    // { name: "Motorista", path: "/motoristas", component: DriversList },
    {
      name: "Cadastro de remessas",
      path: "/transportadoras/:transportadoraId/remessas/cadastro",
      component: RemessaCadastro,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Criar Remessa",
      },
    },
    {
      name: "Cadastro de Remessas",
      path: "/transportadoras/:transportadoraId/remessas/cadastro",
      component: RemessaCadastro,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Criar Remessa",
      },
    },
    {
      name: "Edição de Coletas",
      path: "/transportadoras/:transportadoraId/remessas/:remessaId/coleta",
      component: Coleta,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Atualizar Coleta",
      },
    },
    {
      name: "Detalhes",
      path: "/detalhes-recepcao",
      component: VehicleReception,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Ver Detalhes De Protocolos",
      },
    },
    // {
    //   name: "Gerar Protocolo",
    //   path: "/gerar-protocolo",
    //   component: GenerateProtocol,
    // },
    // { name: "Manifesto", path: "/manifesto/:id", component: VolumeList },
    {
      name:
        store.getters["login/getFilial"].id === 1
          ? "Embarque de Coleta (Matriz)"
          : "Embarque de Coleta (Regional Norte)",
      path: "/coletas-agendadas/matriz/:coletaId/embarque",
      component: EmbarqueColeta,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Iniciar Embarque Da Coleta",
      },
    },
    {
      name: "Embarque de Cooktop",
      path: "/cooktops-agendados/matriz/:coletaId/embarque",
      component: EmbarqueCooktop,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Iniciar Embarque Da Coleta", //TODO: add Permission.
      },
    },
    {
      name: "Embarque de Coleta (Filial Bahia)",
      path: "/coletas-agendadas/filial-bahia/:coletaId/embarque",
      component: EmbarqueRedespacho,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Iniciar Embarque Da Coleta", //TODO: add Permission.
      },
    },
    {
      name: "Recebimento de Coleta",
      path: "/recebimento-coleta/:coletaId/embarque",
      component: RecebimentoColeta,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Iniciar Embarque Da Coleta", //TODO: add Permission.
      },
    },
    { path: "*", redirect: { name: "Dashboard" } },
    {
      name: "Detalhes da Coleta",
      path: "/remessas-transportadora/:remessaId/coleta",
      component: ColetaTransportadoraView,
      meta: {
        needsAuthorization: true,
        needsPermission: true,
        permission: "Ver Lista de Remessas Transportadora",
      },
    },
    {
      name: "",
      path: "/nao-encontrado",
      component: NotFoundView,
    },
    { path: "*", redirect: "/nao-encontrado" },
  ],
});

router.beforeEach((to, from, next) => {
  const isUserLoggedIn = store.getters["login/getLogin"];
  const userPermissions = store.state.login.myPermissions;
  const routePermission = to?.meta?.permission;
  const routeNeedsPermission = to?.meta?.needsPermission ?? false;
  const userHasPermission = userPermissions.includes(routePermission);
  const isRouteSubmenu = to?.meta?.isSubmenu ?? false;
  const isAlreadyOnLoginScreen = to.name === "Login";
  const isRegistrationRoute = to.path.startsWith("/cadastro-usuario-");
  const isRegistrationTransportadoraRoute =
    to.path.startsWith("/cadastro-usuario/");
  const redirectToLoginScreen = (isAlreadyOnLoginScreen) =>
    isAlreadyOnLoginScreen ? next() : next({ name: "Login" });
  if (isUserLoggedIn) {
    if (!isRouteSubmenu) {
      if (routeNeedsPermission) {
        if (userHasPermission) {
          next();
          return;
        }
        next({ name: "Dashboard" });
        return;
      }
      next();
      return;
    }
    next({ name: "Dashboard" });
    return;
  } else if (isRegistrationRoute) {
    next();
    return;
  } else if (isRegistrationTransportadoraRoute) {
    next();
    return;
  } else {
    redirectToLoginScreen(isAlreadyOnLoginScreen);
  }
});

// function beforeEnter(to, from, next) {
//   if (store.getters["login/getPermisssions"]) {
//     next();
//   } else {
//     next({
//       name: "Login",
//     });
//   }
// }

// const router = new VueRouter({
//   mode: "history",
//   base: "",
//   routes,
// });

export default router;
