import { HttpRestService } from "@/services/http/HttpRestService";
import store from "../../store/store";

export class ColetaRepository {
  static requestColeta(url) {
    return HttpRestService.get(url);
  }

  static dataColetaCadastro(transportadoraId) {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/transportadora/${transportadoraId}/coleta/cadastro?unidadeId=${this.unidadeId}`
    );
  }

  static cadastrarColeta(payload) {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.post(
      `/coleta/criar?unidadeId=${this.unidadeId}`,
      payload
    );
  }

  static editarColeta(payload) {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.post(
      `/coleta/editar?unidadeId=${this.unidadeId}`,
      payload
    );
  }

  static dataParaEditarColeta(coletaId) {
    this.unidadeId = store.getters["login/getFilial"].id;

    return HttpRestService.get(
      `/coleta/${coletaId}?unidadeId=${this.unidadeId}`
    );
  }

  static listDocks() {
    return HttpRestService.get(`/docas/listar`);
  }

  static requestConfirmCancellation(modelConfirmCancellation) {
    return HttpRestService.post(`/coleta/cancelar`, modelConfirmCancellation);
  }
}
